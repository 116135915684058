
.main-wrapper {
  height: 100%;
  padding: 0 30px;
  min-width: 800px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .title {
    text-align: center;
    padding-bottom: 30px;
    .titleData {
      font-weight: bold;
    }
  }
  .footer-button {
    text-align: center;
    padding-top: 35px;
  }
  .main-content {
    display: flex;
    .space {
      white-space: nowrap;
    }
    .left-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
      .left-foot {
        margin-top: 80px;
      }
    }
    .middle-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
    }
    .right-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
    }
    .demo-ruleForm {
      display: flex;
      width: 100%;
    }
  }
}
